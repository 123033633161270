import { useContext, useEffect, useState } from "react";
import { GlobalDataContext } from "../../context/context";
import axios from "axios";
import { FaStar } from "react-icons/fa";


export default function CustomReviews() {

    const { rpdata } = useContext(GlobalDataContext);

    const [reviewsDB, setReviewsDB] = useState([]);
    const idCompany = rpdata?.docId;

    useEffect(() => {
        const getReviews = async () => {
            const url = `https://apireviews.herokuapp.com/api/reviews/${idCompany}`;
            const resp = await axios.get(url);
            setReviewsDB(resp.data);
            // console.log(resp.data);
            //order by date
            const reviews = resp.data.sort((a, b) => {
                return new Date(b.Date) - new Date(a.Date);
            });
            setReviewsDB(reviews);
        };
        getReviews();
    }, [idCompany]);
    const totalReviews = reviewsDB.map(item => (item.ReviewsStars >= 3 ? item.ReviewsStars : 0)).reduce((prev, curr) => prev + curr, 0);
    const porcReviews = (totalReviews / reviewsDB.length).toFixed(2)

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text-[15px] leading-relaxed">
                {/* {
                    rpdata?.works?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center justify-start">
                                <p className="text-[15px] font-semibold">{item.title}</p>
                                <p className="text-[15px] text-gray-400"> {item.description}</p>
                            </div>
                        );
                    })
                } */}
                {isReadMore ? text.substring(0, 60) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };

    const moverReview = [

        {
            UserName: "Jesse B.",
            Rate: 5.0,
            Date: "Apr 12, 2021",
            Description: "It Was A Common Painting Job In SF: Apartment In A Condo Is Recent, Had Tenants For A Few Years And The Walls Needed A Refresh. Alex Was Flexible With The Date, Could Provide The Exact Painting (Coming From 2 Different Stores), Came With A Colleague To Complete The Job In One Day And The Walls Look Great."
        },
        {
            UserName: "Nicolas W.",
            Rate: 5.0,
            Date: "Nov 11, 2020",
            Description: "Alex Did A Fantastic Job With Our Deck. He Was Very Prompt, Easy To Schedule And Delivered The Job Exactly On Time. I Would Recommend Him Without Reservation And Will Use Him Again."
        },
        {
            UserName: "Noam L.",
            Rate: 5.0,
            Date: "Jun 22, 2016",
            Description: "Alex Was Great! He Was On Time, And Did A Great Job. My Deck Looks Perfect. And He Had The Most Reasonable Quote. I Had 5 People Come By Before Choosing Alex."
        },
        {
            UserName: "Arianne G.",
            Rate: 5.0,
            Date: "Jun 16, 2016",
            Description: "Alex Is Very Professional, Honest And Knowledgeable. On Time And Price Was Reasonable. I Would Recommend His Work To Others. Thank You."
        },
        {
            UserName: "Beverly L.",
            Rate: 5.0,
            Date: "Jul 16, 2020",
            Description: "Working With Alex Was Very Easy For The Complicated And Detailed Project In A Challenging Work Environment. The Results Of Alex And His Team Are Stunning. I Recommend Him And His Team To All My Friends And Peers."
        },
        {
            UserName: "Donald D.",
            Rate: 5.0,
            Date: "Jun 2, 2017",
            Description: "Alex Is Just The Best. This Was My Third Time Hiring Him And USAL Painting For A Big, Complex Job. They Did A Fantastic Job, As Always, With No Oversight Required. He Is The Best!!"
        },
        {
            UserName: "Kristin E.",
            Rate: 5.0,
            Date: "Apr 29, 2017",
            Description: "Alex Painted My Bathroom And Did A Fantastic Job. Right From The First Interaction On Thumbtack, He Was Responsive And Courteous. Alex Is A Man Of Few Words But Is A Very Friendly, Down-To-Earth Guy Who Gets Right Down To Business When He Shows Up. He's Thorough, Tidy, And Has A Very High Standard For His Work. I HIGHLY Recommend Him For Your Painting Projects."
        },
        {
            UserName: "Shannon R.",
            Rate: 5.0,
            Date: "Oct 3, 2016",
            Description: "Alex Was Great. On-Time, Solid Communication And Did An Excellent Job Painting Our Hallway And Kitchen. Would Definitely Hire Again. Thanks!"
        },
        {
            UserName: "Mel S.",
            Rate: 5.0,
            Date: "Jun 8, 2016",
            Description: "Alex Was GREAT To Work With. He Was Upfront And Honest About The Cost And Scope Of Work. We Had A Few Scheduling Issues But Worked Through It Well. I Would Not Hesitate To Hire Alex Again."
        },

        {
            UserName: "Alex M.",
            Rate: 5.0,
            Date: "Apr 12, 2016",
            Description: "Alex Was So Helpful And Flexible, Arranging Convenient Times To Meet And Work, Helping Us With Paint Selection...And The Work Was Perfect! We Had Two Accent Walls Painted In A Deep Grey And Are Very Pleased With The Results. I Would Definitely Recommend And Have Alex Paint For Us Again 🙂"
        },
        {
            UserName: "Tor C.",
            Rate: 5.0,
            Date: "Mar 31, 2016",
            Description: "I Had A 3 Level Stairwell, A Gate, And Some Trim That Badly Needed Repainting. Alex Came By For A Quote Then Later Came Back With His Crew To Do The Job. They Did A Fantastic Job And The Space Looks So Much Better. Alex Was Friendly And Professional And Would Recommend To Anyone."
        },
        {
            UserName: "John g.",
            Rate: 5.0,
            Date: "Apr 4, 2016",
            Description: "Alex Did An Outstanding Job On My Storefront Awning. He Was On-Time And Professional And Not Only Did He Clean My Awning, He Cleaned The Whole Sidewalk And Front Of My Salon As Well. His Prices Are Extremely Reasonable. He Is A Very Nice Man And I Will Definitely Use Him For Other Things In The Future. Thanks Alex!"
        },
        {
            UserName: "Tina B.",
            Rate: 5.0,
            Date: "Feb 15, 2015",
            Description: "We Had A Last Minute House Painting Job (Other Painter Fell Through Two Days Before He Was Supposed To Start) And A Very Short Window Of Time To Have The Painting Done. Alex Was Able To Get A Team Out To Paint Our House In The Timeframe We Needed. Job Was Good -- Better Than Good For The Great Price! We Would Absolutely Use Usal Painting Again"
        },
        {
            UserName: "Amanda M.",
            Rate: 4.0,
            Date: "Jan 28, 2016",
            Description: "Painting Of All Ceilings And Trim In One-Bedroom Condo. The Guys Did A Great Job And Would Have Been 5 Stars, Had They Not Turned Up 1 Hour Late."
        },
        {
            UserName: "Gaz L.",
            Rate: 4.0,
            Date: "Jul 18, 2016",
            Description: "Alex And His Team Helped Paint Nearly The Entire Interior Of Our House. The Price Was Extremely Competitive, And Overall The Work Was Done Well. We Have One Huge Accent Wall And The Team Did A Beautiful Job On It - It's Done Perfectly! I Do Wish That Some Members Of His Crew Had Better Attention To Detail; We Are Still Cleaning Up Overspray That Got On Our Hardwood Floors And Drips On Our Kitchen Cabinets. Alex Was Pretty Ambitious With The Original Time Estimate He Gave Us, And To Be Honest, We Would Have Been Happy With Something Longer - It Would Have Given Us Less Anxiety Around When The Job Would Actually Be Complete. While I Wish The Quality Control Had Been Better, For The Price, The Work Was Solid."
        },
        {
            UserName: "Anne D.",
            Rate: 5.0,
            Date: "Jul 6, 2015",
            Description: "Called Alex And He Was Very Flexible With Scheduling, Since My Schedule Is Always Busy. All I Had To Provide Was The Paint For Him To Paint My 2 Story Entryway. I Am Very Pleased With His Work, And I Will Probably Use Him Again."
        },
        {
            UserName: "Maya S.",
            Rate: 5.0,
            Date: "May 11, 2015",
            Description: "Alex Was Excellent. He Was Very Responsive Before He Came To Paint Our House And Was Punctual. He Did A Great Job With The Painting And He Was Also Very Helpful, Courteous And Passionate About His Work. I'm So Glad We Went With Him And I Plan To Use Usalbull Painting Again In A Couple Of Months."
        },
        {
            UserName: "Nilkanth P.",
            Rate: 5.0,
            Date: "N/A",
            Description: "Hired On Thumbtack. Alex Did A Great Job And Was Very Flexible. He Helped Me Talk Through My Ideas And Provided Solutions. I Would Recommend Him To Anyone Looking For An Interior Painter!"
        },
        {
            UserName: "Tess T.",
            Rate: 5.0,
            Date: "Jun 6, 2015",
            Description: "I Highly Recommend Alex! He Was Helpful In Making Aesthetic Choices, And Our House Looks Great."
        },
        {
            UserName: "Amy N.",
            Rate: 5.0,
            Date: "Jan 27, 2017",
            Description: "Alex Was Friendly, Professional, Efficient, And Affordable. Can't Recommend These Guys Highly Enough!!!"
        },
        {
            UserName: "Sarah M.",
            Rate: 5.0,
            Date: "Sep 13, 2016",
            Description: "We Had A Great Experience Working With Alex And Team. We Will Definitely Be Giving Him A Call In The Future."
        },
        {
            UserName: "Gabriele T.",
            Rate: 5.0,
            Date: "Jul 12, 2016",
            Description: "On Time, Fast And Amazing Job Painting Two Of Our Rooms. Highly Recommend!"
        },
        {
            UserName: "Sarahjane S.",
            Rate: 5.0,
            Date: "Mar 15, 2016",
            Description: "Alex And His Team Were Great And Friendly. Would Definitely Hire Them Again."
        },
        {
            UserName: "Lauren A.",
            Rate: 1.0,
            Date: "Sep 26, 2016",
            Description: "He Missed Our Appointment And Failed To Call Tell Me He Had To Head Out Of Town. Wasted My Time."
        },
        {
            UserName: "Lori C.",
            Rate: 4.0,
            Date: "Jan 2, 2018",
            Description: "Very Good, Good Communications And Reliable!"
        },
        {
            UserName: "Wen G.",
            Rate: 5.0,
            Date: "Apr 14, 2017",
            Description: "The Paint Job Looks Great!"
        },
        {
            UserName: "Kevin M.",
            Rate: 5.0,
            Date: "Mar 21, 2016",
            Description: "Professional And Quick! A++"
        },
        {
            UserName: "Heidi A.",
            Rate: 5.0,
            Date: "Mar 9, 2016",
            Description: "N/A"
        }

    ]


    return (
        <section className="w-full flex flex-col items-center justify-center py-20 h-auto ">
            <div className="w-full flex flex-col bg-[#f3f3f3] h-auto rounded-xl">
                <div className="px-5 justify-start flex py-5 text-center flex-wrap">
                    <div className="flex items-center border-gray-500 border-b-[4px]">
                        <p className="px-2">All Reviews</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>

                    <div className="flex px-5 items-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2Fgoogle%20min.png?alt=media&token=40cf9139-1153-40c7-85cb-a011d3b848d9" alt="google svg" className="w-[25px] h-[25px]" />
                        <p className="px-2">Google</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>
                    {/* 
                    <div className="flex items-center">
                        <img src="https://acortar.link/cgmMNf" alt="google svg" className="w-[20px] h-[20px]" />
                        <p className="px-2">Yelp</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div> */}
                </div>

                <div className="flex w-full h-auto px-5 border-t justify-between">
                    <div className="flex flex-col">
                        <div><p className="text-[20px]">Overall Rating</p></div>
                        <div className="flex">
                            <div><p className="font-bold text-[18px]">5.0</p></div>
                            <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2`}>
                                <div className="flex">
                                    <FaStar className="text-[#ff4c4c] text-[20px]" />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-7">
                        <a href={rpdata?.gmb?.link} className="p-3 bg1 rounded-md text-white font-bold" target="_blank" rel='noopener noreferrer'>
                            Write a Review
                        </a>
                    </div>
                </div>
            </div>

            <div className="w-full h-auto my-10 mx-auto gap-5 grid md:grid-cols-5 grid-cols-1">
                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto">

                                <div className="p-5 flex flex-col rounded-[28px] items-start mb-2 bg-[#f3f3f3] w-auto relative">
                                    <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2 `}>
                                        <div className="flex">
                                            <FaStar className="text-[#ff4c4c] text-[20px]" />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar ar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                        </div>
                                    </div>
                                    <ReadMore>{item?.Description}</ReadMore>

                                </div>
                                <div className="bg-[#f3f3f3] w-[28px] h-[18px] relative overflow-hidden rounded-br-[30px] -mt-2 ml-7">
                                    <div className="w-[45px] h-[100px] bg-white rounded-full absolute -left-8 -bottom-1">
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col">
                                        <div className="py-4 flex space-x-2">
                                            <div className="flex justify-center">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                    alt="perfil-foto"
                                                    className="w-[40px] h-[40px] rounded-full object-cover" />
                                            </div>

                                            <div>
                                                <div>
                                                    <span className="text-[15px] font-semibold"> {item?.UserName} </span>
                                                </div>
                                                <div className="flex items-end text-[11px] text-gray-400">
                                                    <span>{item?.Date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
        </section>
    );
}
